<script setup lang="ts">
const activeRouteClass = 'text-stone-600 dark:text-stone-300';
const nonActiveRouteClass = 'text-stone-600 dark:text-stone-300';

interface Props {
    title: string;
    to: string;
}
const props = defineProps<Props>();
const { title, to } = toRefs(props);

// COMPUTED
const isActiveRoute = computed<boolean>(() => useRoute().path.includes(to.value));
</script>
<template>
    <NavigationMenuLink as-child>
        <NuxtLink :to="to" class="block p-3 rounded-xl hover:bg-stone-300/70 dark:hover:bg-stone-900/70 w-full transition" :class="[isActiveRoute ? activeRouteClass : nonActiveRouteClass]">
            <p class="text-lg font-medium">{{ title }}</p>
            <p class="text-sm !font-normal"><slot></slot></p>
        </NuxtLink>
    </NavigationMenuLink>
</template>
