<script setup lang="ts">
import NavbarItem from './NavbarItem.vue';
import UserMenu from './UserMenu.vue';
import Notification from './Notification.vue';
import MobileNavbar from './MobileNavbar.vue';

// INJECT
const colorMode = useColorMode();
const viewPort = useViewport();

// COMPUTED
const logo = computed(() => {
    if (colorMode?.value === 'dark') {
        return '/image/logo-white.png';
    } else {
        return '/image/logo-red.png';
    }
});
</script>
<template>
    <nav class="bg-inherit py-2 px-5 z-50 w-full">
        <div class="flex flex-wrap justify-between items-center">
            <NuxtLink class="flex-none" to="/">
                <img :src="logo" class="w-32 logo" />
            </NuxtLink>

            <!-- ITEM -->
            <NavbarItem v-if="viewPort.isGreaterThan('sm')" class="lg:order-2" />

            <!-- USER MENU -->
            <div class="order-3 flex items-center gap-3">
                <MobileNavbar v-if="viewPort.isLessThan('md')" />
                <Notification />
                <UserMenu />
            </div>
        </div>
    </nav>
</template>
