<script setup lang="ts">
import NotificationContent from './NotificationContent.vue';
import ToastProvider from '../ui/toast/ToastProvider.vue';
import Toast from '../ui/toast/Toast.vue';
import type { Notification } from '~/types/Notification';
import ToastViewport from '../ui/toast/ToastViewport.vue';
import type { Socket } from 'socket.io-client';
import NotificationList from './NotificationList.vue';

// VARIABLE
const notiService = useNotification();
const notificationCount = ref<number>(0);
const notificationAlertOpen = ref<boolean>(false);
const newNotification = ref<Notification | null>(null);
const content = ref<InstanceType<typeof NotificationContent> | null>(null);

// SOCKET
const socket = inject<Socket>('notificationSocket');
if (socket) {
    socket.on('newNotification', (data: Notification) => {
        notificationCount.value++;
        newNotification.value = data;
        notificationAlertOpen.value = true;
        new Notification(data.title, { body: data.description || '' });
        new Audio('/audio/notification.mp3').play();
    });
}

// FUNCTION
async function getNotificationNum() {
    const num = await notiService.getNotificationNumber();

    return num;
}

async function markAllAsSeen() {
    content.value?.markAllAsSeen();
    notificationCount.value = 0;
    await notiService.markAllAsSeen();
}

// ONMOUNTED
onMounted(async () => {
    notificationCount.value = await getNotificationNum();
});
</script>
<template>
    <div>
        <Popover>
            <PopoverTrigger>
                <Button class="px-2 rounded-full" variant="ghost">
                    <IconCSS name="material-symbols:notifications-outline-rounded" size="1.5rem" />
                    <Badge>{{ notificationCount }}</Badge>
                </Button>
            </PopoverTrigger>

            <PopoverContent class="w-[30rem]">
                <div class="flex justify-between items-center mb-1">
                    <p class="text-xl font-medium">การแจ้งเตือนทั้งหมด</p>
                    <DropdownMenu>
                        <DropdownMenuTrigger as-child>
                            <Button class="px-2 rounded-full" variant="ghost">
                                <IconCSS name="material-symbols:more-vert" size="1.25rem" />
                            </Button>
                        </DropdownMenuTrigger>

                        <DropdownMenuContent class="w-48 z-50">
                            <DropdownMenuGroup>
                                <DropdownMenuItem @click="markAllAsSeen()">อ่านทั้งหมดแล้ว</DropdownMenuItem>
                            </DropdownMenuGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                <NotificationContent ref="content" />
            </PopoverContent>
        </Popover>

        <ToastProvider :duration="10000">
            <Toast v-model:open="notificationAlertOpen">
                <NotificationList v-if="newNotification" :notification="newNotification" :is-seen="true" />
            </Toast>

            <ToastViewport />
        </ToastProvider>
    </div>
</template>
