<script setup lang="ts">
import ThemeControl from './ThemeControl.vue';
import Avatar from '../ui/avatar/Avatar.vue';
import AvatarImage from '../ui/avatar/AvatarImage.vue';
import AvatarFallback from '../ui/avatar/AvatarFallback.vue';
import DropdownMenu from '../ui/dropdown-menu/DropdownMenu.vue';
import DropdownMenuTrigger from '../ui/dropdown-menu/DropdownMenuTrigger.vue';
import DropdownMenuContent from '../ui/dropdown-menu/DropdownMenuContent.vue';
import DropdownMenuLabel from '../ui/dropdown-menu/DropdownMenuLabel.vue';
import DropdownMenuItem from '../ui/dropdown-menu/DropdownMenuItem.vue';
import DropdownMenuSeparator from '../ui/dropdown-menu/DropdownMenuSeparator.vue';

// VARIABLE
const { $api } = useNuxtApp();
const { data: user, signOut } = useAuth();

// COMPUTED
const avatarLabel = computed<string>(() => `${user.value?.firstName.substring(0, 1).toUpperCase()}${user.value?.firstName.substring(0, 1).toUpperCase()}`);

// FUNCTION
function signingOut() {
    signOut({ callbackUrl: '/login' });
    location.reload();
}
</script>
<template>
    <DropdownMenu>
        <DropdownMenuTrigger>
            <Avatar>
                <AvatarImage :src="$api(user?.imageUrl)" :alt="user?.fullName" />
                <AvatarFallback>{{ avatarLabel }}</AvatarFallback>
            </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent class="w-56 z-[52]">
            <DropdownMenuGroup>
                <DropdownMenuLabel>
                    <p class="text-lg font-medium">{{ user?.fullName }}</p>
                    <small class="text-sm">{{ user?.position }}</small>
                </DropdownMenuLabel>
                <DropdownMenuSeparator class="bg-stone-200" />
                <DropdownMenuItem as-child><ThemeControl /></DropdownMenuItem>
                <DropdownMenuItem>
                    <NuxtLink class="w-full" to="/settings">
                        <Icon name="material-symbols:settings-outline-rounded" size="1.25rem" />
                        Settings
                    </NuxtLink>
                </DropdownMenuItem>
                <DropdownMenuItem class="cursor-pointer" @click="signingOut">
                    <Icon name="material-symbols:logout-rounded" size="1.25rem" />
                    Logout
                </DropdownMenuItem>
            </DropdownMenuGroup>
        </DropdownMenuContent>
    </DropdownMenu>
</template>
