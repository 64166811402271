<script setup lang="ts">
import NavBarButton from './NavBarButton.vue';
import StoreButton from './Store/NavBarButton.vue';
import { useAuthStore } from '@/stores/useAuthStore';
import NavigationMenu from '../ui/navigation-menu/NavigationMenu.vue';
import NavigationMenuList from '../ui/navigation-menu/NavigationMenuList.vue';
import NavigationMenuTrigger from '../ui/navigation-menu/NavigationMenuTrigger.vue';
import NavigationMenuContent from '../ui/navigation-menu/NavigationMenuContent.vue';

const { isManager, isStore, isSuperAdmin } = useAuthStore();

const activeRouteClass = 'text-primary-400 dark:text-primary-500 dark:text-primary-dark-500 bg-stone-500 dark:bg-stone-800 hover:bg-stone-800/60 border-stone-500 dark:border-stone-800';
const nonActiveRouteClass = 'text-stone-500 hover:text-stone-300 border-stone-300 dark:border-stone-700 hover:!bg-stone-500 hover:border-stone-500 dark:hover:bg-stone-700';

// VARIABLE
const currentCategoryTrigger = ref<string>('');

// COMPUTED
const hasStorePermission = computed(() => usePermission('product.view') || usePermission('warehouse.view'));

// FUNCTION
function isActiveRoute(path: string) {
    return useRoute().path.includes(path);
}
</script>
<template>
    <NavigationMenu v-model="currentCategoryTrigger" class="items-center z-[100] justify-between w-full md:flex md:w-auto">
        <NavigationMenuList class="list-none flex mt-4 flex-col font-medium w-full md:flex-row md:mt-0 gap-2">
            <!-- PROJECT -->
            <NavigationMenuItem v-if="usePermission('project.view')">
                <NuxtLink class="w-full" to="/project">
                    <Button
                        variant="secondary-900"
                        :class="[isActiveRoute('/project') ? activeRouteClass : nonActiveRouteClass]"
                        class="transition flex items-center w-full py-2 px-3 rounded-full font-medium border md:w-auto w-[20rem]"
                    >
                        <IconCSS name="material-symbols:folder-rounded" size="1rem" class="mr-1"></IconCSS>
                        <p :class="{ 'text-white': isActiveRoute('/project') }">โปรเจกต์</p>
                    </Button>
                </NuxtLink>
            </NavigationMenuItem>

            <!-- PURCHASE -->
            <NavigationMenuItem>
                <NavigationMenuTrigger
                    class="group select-none !w-[20rem] transition flex items-center justify-between w-full py-2 px-3 rounded-full font-medium border md:!w-auto"
                    :class="[isActiveRoute('/purchases') ? activeRouteClass : nonActiveRouteClass]"
                >
                    <IconCSS name="material-symbols:shopping-cart-rounded" size="1rem" class="mr-1"></IconCSS>
                    <p :class="{ 'text-white': isActiveRoute('/purchases') }">ซื้อ</p>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                    <ul class="p-2 w-[31rem]">
                        <li v-if="usePermission('supplier.view')">
                            <NavBarButton to="/purchases/suppliers" title="ซัพพลายเออร์">จัดการข้อมูลซัพพลายเออร์ของสมศรี</NavBarButton>
                        </li>

                        <li v-if="usePermission('pr.view')">
                            <NavBarButton to="/purchases/purchase-requisition" title="ใบ PR">เตรียมรายการจัดซื้อให้กับงานต่าง ๆ กัน📋</NavBarButton>
                        </li>
                        <li v-if="usePermission('po.view')">
                            <NavBarButton to="/purchases/purchase-order" title="ใบ PO">เตรียมคำสั่งซื้อให้กับงานต่าง ๆ กัน 🧾</NavBarButton>
                        </li>
                        <li v-if="usePermission('gr.view')">
                            <NavBarButton to="/purchases/goods-receive" title="ใบรับของ(GR)">เตรียมรายการรับของที่เราได้สั่งซื้อกัน📋</NavBarButton>
                        </li>
                    </ul>
                </NavigationMenuContent>
            </NavigationMenuItem>

            <!-- SELL -->
            <NavigationMenuItem>
                <NavigationMenuTrigger
                    class="group select-none transition flex items-center justify-between w-full !w-[20rem] py-2 px-3 rounded-full font-mediummd:w-auto border md:!w-auto"
                    :class="[isActiveRoute('/sales') ? activeRouteClass : nonActiveRouteClass]"
                >
                    <IconCSS name="material-symbols:paid-rounded" size="1rem" class="mr-1"></IconCSS>
                    <p :class="{ 'text-white': isActiveRoute('/sales') }">ขาย</p>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                    <ul class="p-2 w-[31rem]">
                        <li v-if="usePermission('quotation.view')">
                            <NavBarButton to="/sales/quotation" title="ใบเสนอราคา">ออกใบเสนอราคาให้กับลูกค้าของคุณ🫵</NavBarButton>
                        </li>
                        <li v-if="usePermission('specSheet.view')">
                            <NavBarButton to="/sales/spec-sheet" title="ใบสเปค">เพิ่มรายละเอียดการผลิตให้กับสินค้าของคุณ</NavBarButton>
                        </li>
                        <li>
                            <NavBarButton to="/sales/customer" title="ลูกค้า">จัดการข้อมูลลูกค้าของ Texcel</NavBarButton>
                        </li>
                    </ul>
                </NavigationMenuContent>
            </NavigationMenuItem>

            <!-- PRODUCTION -->
            <NavigationMenuItem>
                <NavigationMenuTrigger
                    class="group select-none !w-[20rem] transition flex items-center justify-between w-full py-2 px-3 rounded-full font-medium border md:!w-auto"
                    :class="[isActiveRoute('/production') ? activeRouteClass : nonActiveRouteClass]"
                >
                    <IconCSS name="material-symbols:factory-rounded" size="1rem" class="mr-1"></IconCSS>
                    <p :class="{ 'text-white': isActiveRoute('/production') }">ผลิต</p>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                    <ul class="p-2 w-[31rem]">
                        <li v-if="usePermission('workOrder.view')">
                            <NavBarButton to="/production/work-order" title="ใบสั่งผลิต">ออกคำสั่งผลิตและรายละเอียดให้กับพนักงานในสมศรี👷‍♂️</NavBarButton>
                        </li>
                    </ul>
                </NavigationMenuContent>
            </NavigationMenuItem>

            <!-- STORE -->
            <NavigationMenuItem v-if="hasStorePermission">
                <NavigationMenuTrigger
                    class="group select-none !w-[20rem] transition flex items-center justify-between w-full py-2 px-3 rounded-full font-medium border md:!w-auto"
                    :class="[isActiveRoute('/store') ? activeRouteClass : nonActiveRouteClass]"
                >
                    <IconCSS name="material-symbols:inventory-2-rounded" size="1rem" class="mr-1"></IconCSS>
                    <p :class="{ 'text-white': isActiveRoute('/store') }">คลัง</p>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                    <ul class="p-2">
                        <li v-if="usePermission('product.view')" :class="['cursor-pointer', isActiveRoute('/store/product')]">
                            <StoreButton name="รายการสินค้า" icon="material-symbols:box-outline-rounded" to="/store/product" details="เพิ่มรายการสินค้า"></StoreButton>
                        </li>
                        <li v-if="usePermission('adjustment.view')" :class="['cursor-pointer', isActiveRoute('/store/adjustment')]">
                            <StoreButton name="ปรับปรุงสต็อก" icon="material-symbols:sync-rounded" to="/store/adjustment" details="ปรับปรุงสต็อกของ Texcel"></StoreButton>
                        </li>
                        <li v-if="usePermission('materialRequest.view')" class="cursor-pointer">
                            <StoreButton name="เบิกวัตถุดิบ(MR)" icon="material-symbols:export-notes-outline-rounded" to="/store/material-request" details="สำหรับสร้างใบเบิกวัตถุดิบ"></StoreButton>
                        </li>
                        <li v-if="usePermission('materialDelivery.view')" class="cursor-pointer">
                            <StoreButton name="ส่งวัตถุดิบ(MD)" icon="material-symbols:article-shortcut-outline" to="/store/material-delivery" details="สำหรับสร้างใบส่งวัตถุดิบ"></StoreButton>
                        </li>
                        <li v-if="usePermission('materialSurplusReturn.view')" class="cursor-pointer">
                            <StoreButton name="คืนวัตถุดิบ(MS)" icon="material-symbols:restart-alt-rounded" to="/store/material-surplus-return" details="สำหรับสร้างใบคืนวัตถุดิบ"></StoreButton>
                        </li>
                        <li v-if="usePermission('materialSummary.view')" class="cursor-pointer">
                            <StoreButton name="สรุปวัตถุดิบ(MY)" icon="material-symbols:add-task-rounded" to="/store/material-summary" details="สำหรับสร้างใบสรุปวัตถุดิบ"></StoreButton>
                        </li>
                        <li v-if="(isManager && isStore) || isSuperAdmin" class="cursor-pointer">
                            <StoreButton name="ประเภทสินค้า" icon="material-symbols:box-outline-rounded" to="/store/product-category" details="สำหรับเพิ่มประเภทสินค้า"></StoreButton>
                        </li>
                        <li v-if="(usePermission('product.view') && isManager) || isSuperAdmin" class="cursor-pointer">
                            <StoreButton name="ประเภทบัญชีของสินค้า" icon="material-symbols:box-outline-rounded" to="/store/store-type" details="สำหรับเพิ่มประเภทบัญชีของสินค้า"></StoreButton>
                        </li>
                        <li v-if="usePermission('warehouse.view')" class="cursor-pointer">
                            <StoreButton name="คลังสินค้า" icon="material-symbols:warehouse-outline-rounded" to="/store/warehouse" details="สำหรับสร้างคลังสินค้า"></StoreButton>
                        </li>
                    </ul>
                </NavigationMenuContent>
            </NavigationMenuItem>
        </NavigationMenuList>
    </NavigationMenu>
</template>
