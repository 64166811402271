import type { GetApiOptions } from '~/types/Global';
import type { Notification } from '~/types/Notification';
import type { NotificationRecipient } from '~/types/NotificationRecipient';

const defaultGetApiOptions: GetApiOptions = {};

const BASE_URL = '/v1/notifications';

export function useNotification() {
    async function getAll(options: GetApiOptions = defaultGetApiOptions): Promise<(NotificationRecipient & { notification: Notification })[]> {
        try {
            const { data, error } = await useApiFetch<(NotificationRecipient & { notification: Notification })[]>(`${BASE_URL}/get`, {
                method: 'POST',
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return [];
        }
    }

    async function getNotificationNumber(options: GetApiOptions = defaultGetApiOptions): Promise<number> {
        try {
            const { data, error } = await useApiFetch<{ notification: number }>(`${BASE_URL}/get-unseen-number`, {
                method: 'POST',
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value.notification;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return 0;
        }
    }

    async function markAllAsSeen(options: GetApiOptions = defaultGetApiOptions): Promise<number> {
        try {
            const { data, error } = await useApiFetch<{ notification: number }>(`${BASE_URL}/mark-all-as-seen`, {
                method: 'POST',
            });

            if (error.value) throw error.value;
            if (!data.value) throw new Error('NO RESPONSE');

            return data.value.notification;
        } catch (err) {
            useBugsnag().notify(JSON.stringify(err));

            if (options.errorCallback) options.errorCallback();

            return 0;
        }
    }

    return {
        getAll,
        getNotificationNumber,
        markAllAsSeen,
    };
}
