<script setup lang="ts">
import Select from '../ui/select/Select.vue';
import SelectTrigger from '../ui/select/SelectTrigger.vue';
import SelectValue from '../ui/select/SelectValue.vue';
import SelectContent from '../ui/select/SelectContent.vue';
import SelectGroup from '../ui/select/SelectGroup.vue';
import SelectItem from '../ui/select/SelectItem.vue';

// VARIABLE
const colorMode = useColorMode();
const colorModeValue = ref<string>(colorMode.preference);

// FUNCTION
function setTheme(color: string) {
    colorMode.preference = color;
}

const valueIcon = computed<string>(() => {
    switch (colorModeValue.value) {
        case 'light':
            return 'material-symbols:light-mode-outline-rounded';
        case 'dark':
            return 'material-symbols:nightlight-outline-rounded';
        case 'system':
            return 'material-symbols:computer-outline-rounded';
        default:
            return '';
    }
});

watch(colorModeValue, () => {
    setTheme(colorModeValue.value);
});
</script>
<template>
    <div>
        <Select v-model="colorModeValue">
            <SelectTrigger class="w-full">
                <IconCSS :name="valueIcon"></IconCSS>
                <SelectValue />
            </SelectTrigger>
            <SelectContent class="z-[60]">
                <SelectGroup>
                    <SelectItem value="light">Light</SelectItem>
                    <SelectItem value="dark">Dark</SelectItem>
                    <SelectItem value="system">System</SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>
    </div>
</template>
