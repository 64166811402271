<script setup lang="ts">
import type { NotificationRecipient } from '~/types/NotificationRecipient';
import NotificationList from './NotificationList.vue';
import type { Notification } from '~/types/Notification';

// EXPOSE
defineExpose({ markAllAsSeen });

// VARIABLE
const notiService = useNotification();
const notifications = ref<(NotificationRecipient & { notification: Notification })[]>(await notiService.getAll());

// FUNCTION
function markAllAsSeen() {
    notifications.value = [];
}

// COMPUTED
const hasNotification = computed<boolean>(() => notifications.value.length > 0);
</script>
<template>
    <div class="max-h-[20rem] overflow-auto">
        <template v-if="hasNotification">
            <NotificationList v-for="noti of notifications" :key="noti.id" class="p-2 hover:bg-stone-200 dark:hover:bg-stone-900" :is-seen="noti.isSeen" :notification="noti.notification" />
        </template>
        <template v-else>
            <p class="mt-3 text-lg text-stone-400 dark:text-stone-500 text-center">ไม่พบการแจ้งเตือน</p>
        </template>
    </div>
</template>
