<script lang="ts" setup>
import Navbar from '@/components/layouts/NavBar.vue';

// PROPS
interface Props {
    pageName?: string | null;
    sidebarPosition?: 'left' | 'right';
}
withDefaults(defineProps<Props>(), {
    pageName: null,
    sidebarPosition: 'left',
});

//VARIABLE
const viewPort = useViewport();
const sideBarView = ref<boolean>(viewPort.isGreaterThan('sm'));

watch(viewPort.breakpoint, () => {
    sideBarView.value = viewPort.isGreaterThan('sm');
});
</script>
<template>
    <div class="bg-stone-100 dark:bg-stone-900 min-h-screen">
        <!-- NAVBAR -->
        <Navbar id="navbar" class="fixed bg-stone-100 dark:bg-stone-900" />

        <div class="p-5 pt-20 grid grid-cols-8 gap-5">
            <div class="flex flex-wrap justify-between items-center" :class="[$slots.topbar ? 'col-span-full sm:col-span-2' : 'col-span-8']">
                <p v-if="pageName" class="text-4xl w-fit max-w-full text-primary-500 dark:text-white font-medium break-words">{{ pageName }}</p>
                <slot name="page-name-item" />
            </div>

            <!-- TOP BAR -->
            <div v-if="$slots.topbar" class="col-span-full sm:col-span-6">
                <slot name="topbar">
                    <div class="w-full rounded-xl h-full bg-stone-800"></div>
                </slot>
            </div>

            <!-- LEFT SIDE BAR -->
            <template v-if="sidebarPosition === 'left'">
                <div v-if="$slots.sidebar" class="col-span-full sm:col-span-2">
                    <div>
                        <Collapsible v-model:open="sideBarView">
                            <CollapsibleTrigger class="w-full justify-start" as-child>
                                <Button variant="secondary">
                                    <IconCSS name="material-symbols:arrow-forward-ios-rounded" size="1.5rem" :class="{ 'rotate-90': sideBarView }" class="transition mr-2" />
                                    แถบเครื่องมือ</Button
                                >
                            </CollapsibleTrigger>
                            <CollapsibleContent class="bg-stone-200 dark:bg-stone-800 rounded-xl p-3 mt-5">
                                <slot name="sidebar">
                                    <p class="text-center font-medium text-stone-500">ไม่มี Filter</p>
                                </slot>
                            </CollapsibleContent>
                        </Collapsible>
                    </div>
                </div>

                <!-- MAIN CONTENT -->
                <div :class="[$slots.sidebar ? 'col-span-full sm:col-span-6' : 'col-span-8']">
                    <slot />
                </div>
            </template>

            <!-- RIGHT SIDE BAR -->
            <template v-if="sidebarPosition === 'right'">
                <div v-if="$slots.sidebar" class="col-span-2">
                    <div class="max-h-[37rem] overflow-y-auto" :class="[$slots.sidebar ? 'col-span-6' : 'col-span-8']">
                        <slot />
                    </div>
                    <div class="rounded-xl p-3 bg-stone-200 dark:bg-stone-800">
                        <slot name="sidebar">
                            <p class="text-center font-medium text-stone-500">ไม่มี Sidebar</p>
                        </slot>
                    </div>
                </div>
            </template>
        </div>

        <!-- BOTTOM FIXED BAR -->
        <div class="fixed bottom-0">
            <slot name="bottomNavbar" />
        </div>
    </div>
</template>
