<script setup lang="ts">
interface Props {
    name: string;
    icon: string;
    to: string;
    details: string;
}

const props = defineProps<Props>();
const { name, icon, to, details } = toRefs(props);
const route = useRoute();
const activeRouteClass = 'text-primary-500 dark:text-primary-dark-500';
const nonActiveRouteClass = 'text-stone-600 dark:text-stone-300';

function isActiveRoute(path: string) {
    return route.path.includes(path) ? activeRouteClass : nonActiveRouteClass;
}
</script>

<template>
    <NuxtLink :to="to" class="min-w-[30rem] w-full" :class="['flex gap-3 p-3 rounded-xl hover:bg-stone-300/70 dark:hover:bg-stone-900 w-full transition', isActiveRoute(to)]">
        <IconCSS :name="icon" size="1.5rem" class="text-primary-500 dark:text-primary-dark-500 mr-3" />
        <div>
            <p class="text-lg font-medium">{{ name }}</p>
            <p class="text-sm font-normal">{{ details }}</p>
        </div>
    </NuxtLink>
</template>
